import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import {
  mediaPhone,
  mediaIPadPro,
  mediaIPad,
} from "../../layouts/styles/Breakpoints";
import Button from "../../layouts/v3/containers/button";
import CCA from "../../layouts/v2/containers/a";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${({ version }) => (version === 2 ? "width: 100%;" : "")}
  margin-left: auto;
  margin-right: auto;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 4rem;
`;

const Input = styled.input`
  margin-right: 1rem;
  text-align: center;
  font-size: 40px;
  width: 50px;
  padding-bottom: 5px;
  ${mediaPhone} {
    font-size: 20px;
  }
  ${mediaIPadPro} {
    font-size: 20px;
  }

  ${mediaIPad} {
    font-size: 20px;
  }
`;

const Actions = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: ${({ version }) => (version === 2 ? "100%;" : "90%")};
  ${mediaPhone} {
    width: 100%;
  }
`;

const ActionsLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TloVerificationEnter = ({
  setTLOEnteredCode,
  enteredCode,
  enteredCodeError,
  goToTLOVerificationInit,
  doTLOResendCode,
  doTLOVerify,
  config,
  showLinks,
}) => {
  let numOfInputs = 5;
  let inputs = [];
  //let code = "";
  let [code, setCode] = useState("");
  let inputRefs = useRef();

  const setValue = (val) => {
    setCode((code += val));
    if (code.length === numOfInputs) {
      setTLOEnteredCode(code);
    }
  };

  if (config.layoutVersion !== 2) {
    const nextFocus = () => {
      let nextSibling = inputRefs.current && inputRefs.current.nextSibling;

      if (nextSibling && nextSibling.tagName === "INPUT") {
        nextSibling.focus();
        inputRefs.current = nextSibling;
      }
    };

    const handleKeyPress = (e) => {
      // delete
      if (e.keyCode === 8) {
        e.preventDefault();
        setCode(code.slice(0, -1));
        inputRefs.current.value = "";
        previousFocus();
      }
    };
    const previousFocus = () => {
      let previousSibling =
        inputRefs.current && inputRefs.current.previousSibling;

      if (previousSibling && previousSibling.tagName === "INPUT") {
        previousSibling.focus();
        inputRefs.current = previousSibling;
      }
    };

    for (let i = 0; i < numOfInputs; i++) {
      let enteredCodeLen =
        enteredCode.length === 0 ? 0 : enteredCode.length - 1;

      inputs.push(
        enteredCodeLen === i ? (
          <Input
            key={i}
            type="text"
            className={`form-control`}
            onKeyDown={handleKeyPress}
            onChange={(e) => {
              if (e.target.value) {
                setValue(e.target.value);
                nextFocus();
              }
            }}
            name={`code_${i}`}
            maxLength={1}
            ref={inputRefs}
          />
        ) : (
          <Input
            key={i}
            type="text"
            className={`form-control`}
            onKeyDown={handleKeyPress}
            onChange={(e) => {
              if (e.target.value) {
                setValue(e.target.value);
                nextFocus();
              }
            }}
            name={`code_${i}`}
            maxLength={1}
          />
        )
      );
    }
  }

  useEffect(() => {
    if (inputRefs && config.layoutVersion !== 2) {
      inputRefs.current.focus();
    }
  }, []);

  const disabledV3 = code.length !== numOfInputs;
  const disabledV2 = enteredCode.length !== numOfInputs;

  return (
    <Container version={config.layoutVersion}>
      {enteredCodeError && (
        <div style={{ marginBottom: "23px", textAlign: "center" }}>
          <span className={"errorMsg"}>{enteredCodeError}</span>
        </div>
      )}
      {config.layoutVersion === 2 ? (
        <div>
          <input
            type="text"
            value={enteredCode}
            className={"form-control"}
            onChange={(e) => setTLOEnteredCode(e.target.value.trim())}
            placeholder={config.tloEnteredCodePlaceHolderTxt}
          />
        </div>
      ) : (
        <InputContainer>{inputs}</InputContainer>
      )}

      {config.layoutVersion === 2 && showLinks && (
        <React.Fragment>
          <ActionsLinks>
            <CCA onClick={doTLOResendCode}>{config.tloResendCodeLinkTxt}</CCA>
            <CCA onClick={goToTLOVerificationInit}>
              {config.tloChooseADifferentPhoneTxt}
            </CCA>
          </ActionsLinks>
        </React.Fragment>
      )}

      {config.layoutVersion === 3 && showLinks && (
        <React.Fragment>
          <div>
            <a onClick={doTLOResendCode}>{config.tloResendCodeLinkTxt}</a>
            <br />
            <a onClick={goToTLOVerificationInit}>
              {config.tloChooseADifferentPhoneTxt}
            </a>
          </div>
        </React.Fragment>
      )}

      <Actions version={config.layoutVersion}>
        {config.layoutVersion === 2 && (
          <input
            type="button"
            onClick={() => {
              doTLOVerify();
            }}
            className={"btn btn-primary activebtn"}
            value={config.tloVerifyBtnTxt}
            disabled={disabledV2}
          />
        )}
        {config.layoutVersion === 3 && (
          <Button
            active={!disabledV3}
            onClick={() => {
              doTLOVerify();
            }}
            disabled={disabledV3}
          >
            {config.tloVerifyBtnTxt}
          </Button>
        )}
      </Actions>
    </Container>
  );
};

TloVerificationEnter.defaultProps = {
  showLinks: true,
};

TloVerificationEnter.propTypes = {
  goToTLOVerificationInit: PropTypes.func,
  doTLOResendCode: PropTypes.func,

  enteredCode: PropTypes.string.isRequired,
  enteredCodeError: PropTypes.string.isRequired,
  setTLOEnteredCode: PropTypes.func.isRequired,
  doTLOVerify: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  showLinks: PropTypes.bool.isRequired,
};

export default TloVerificationEnter;
