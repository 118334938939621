import { configBuilder } from "ccui-framework";
import { commonConfig } from "../../../src/config/partner";

const Config = {
  checkout: {
    ...commonConfig,
    reduxLogger: false,
    variant: "widget",
    showHeader: false,
    showFooter: false,
    //layout
    layoutVersion: 3,
    testOrderTotal: 30,

    exchangeMastTextPartOneV1: "Pay with",
    exchangeMastTextPartTwoV1: "Gift Cards",

    soundGoodAddAnotherCardLinkTxt: "+ Add Another Card",
    monthYearTextPlaceHolder: "MM/YY",
    cvvTextPlaceHolder: "CVV",
    orderCompleteCardInfoTxt: {
      text: (config) =>
        `Order confirmed. You'll be redirected back to the store within 5 seconds. If not, please click the button below.`,
    },
    orderCompleteButtonTxt: "Back to the Store",

    headerBackTo: true,
    tloMissingNumberLink: "Missing Number? Learn why",
    identityTloTitle: "Choose your phone number",
    identityTloPassCodeTitle: "Enter the code ",
    identityTloErrorTitle: "Oops…",
    identityTloExpiredTitle: "Expired",

    tloVerifyBtnTxt: "Continue",

    tloErrorTxt: `<p>Our automated system flagged your order as possibly fraudulent, unfortunately we cannot complete your order.</p>`,

    // Verification
    verificationType: "tlo",
    // meta
    paymentType: "Gift Card",
    aOrAn: "a",
    partnerBrand: "Anycard",
    partnerURL: "http://www.anycard.com/",
    partnerEmail: "support@cardcash.com",
    giftCardTerms: "https://www.anycard.com/about/termsandconditions",
    termsAndConditionsBuilder: (config) => "",

    cardDetailsTitle: "Provide Gift Card Details",
    identityCardInfoTitle: "Enter your credit card info",
    identityBillingInfoTitle: "Enter your billing info",
    soundGoodTitle: "Your Credit",

    // social
    facebookURL: "https://www.facebook.com/CardCash/",
    twitterURL: "https://twitter.com/CardCash",
    youTubeURL: null,
    instagramURL: "https://www.instagram.com/cardcash",
    googleURL: null,
    linkedInURL: null,
    pinterestURL: "https://www.pinterest.com/cardcash/",

    // api
    googleAnalytics: "",
    x_cc_app: "0J69He2q2",
    baseURL: "https://production-api.cardcash.com/",

    // header
    headerMenuItemOrder: ["contactItem", "faqItem"],
    headerMenuFaqItem: {
      text: (config) => "FAQ",
    },

    headerLogoOrder: ["headerLogoBrand", "headerLogoCC"],
    headerLogoBrandIcon:
      "https://s3.amazonaws.com/cdn.cardcash.com/website/partners/anycard/logo.png",
    soundsGoodSaveButtonTxt: "Get Offer",
    // steps
    stepsVersion: 3,
    stepsV3Text: [
      [
        "Get Offer",
        "Tell us about the brand and balance and get an instant offer.",
      ],
      ["Provide Details", "Enter the details for your gift card."],
      [
        "Provide Credit Card",
        "To cover any remaining balance and backup your gift card value.",
      ],
      ["Verify Your Identity", "To help us protect everyone from fraudsters."],
    ],
    faqv3: {
      content: (config) => {
        return [
          {
            question: "How does this work?",
            answer:
              "You now have the option to trade unwanted gift cards from over 200 brands to cover part of your purchase. You will receive an offer for the card(s) and then provide a credit card to cover any remaining balance and backup the value of your gift card(s). The order must be large enough to use the full value of the gift card(s) you provide.",
          },
          {
            question: "How much will I receive in return for my gift card?",
            answer:
              "Every brand is different. In order to find the exchange value for your card, please enter the brand name of the gift card and the balance to receive an offer. You are under no obligation to exchange your card, and we will not retain your gift card information if you do not confirm your exchange.",
          },
          {
            question: "Do you accept partially used cards or uneven balances?",
            answer:
              "Yes, we accept gift cards with any balance over $10 remaining on the gift card.",
          },
          {
            question: "Do I need to mail in my gift card?",
            answer: "No, you do not need to mail in your card.",
          },
          {
            question: `Can I exchange more than one gift card?`,
            answer: `Yes, you can exchange more than one gift card. To trade in more than one, click the "Add Another Card" link.`,
          },
          {
            question: `Can I get better rates for my cards or is it the same for all gift cards?`,
            answer: `Our rates are determined by market pricing, which is affected by several factors. In order to find the rate for your card, please enter your gift card information to receive an offer. You are under no obligation to sell your card, and we will not retain your gift card information if you do not confirm your exchange. Please note that the offer you see displayed for the same gift card of the same value may change from time to time.`,
          },
          {
            question: `"What do you do with the gift cards?`,
            answer: `CardCash.com is one of the biggest gift card exchanges in the world. We repurpose and then resell the gift cards at a discounted rate on our website.`,
          },
          {
            question: "I changed my mind. How do I get my card back?",
            answer: `We're sorry; once you've accepted the offer, the sale is final.`,
          },
          {
            question: `Do you accept merchandise credits?`,
            answer: "Yes, for some merchants.",
          },
          {
            question: "Do you accept cards with expiration dates?",
            answer: `No, unfortunately, we cannot accept gift cards that have an expiration date.`,
          },
          {
            question: "I changed my mind. How do I get my card back?",
            answer: `Until your order is finalized you can cancel your order by contacting customer service.`,
          },
          {
            question: "Do you accept merchandise credits?",
            answer: "Yes.",
          },
          {
            question: "Do you accept cards with expiration dates?",
            answer:
              "No, unfortunately, we cannot accept gift cards that have an expiration date.",
          },
          {
            question: "I’m still confused, how can I reach you?",
            answer: `Our customer service department can be reached during normal business hours or by email at <a href="mailto:support@cardcash.com" target="_blank">support@cardcash.com</a>.`,
          },
        ];
      },
    },
    // offer
    offerButtonTxt: "Get Instant Offer",
    offerButtonTxtMany: "Get Instant Offer",
    offerBalanceInputPlaceHolder: "$ Remaining Balance",

    // card number and pin input boxes
    cardNumberPlaceHolderText: "Gift Card Number",
    cardPinPlaceHolderText: "PIN",

    // Have another card? Add it here?
    haveAnotherCardShowPopup: false,

    sellInstructionsDesktopTitle: "How It Works?",
    exchangeMastTextPartThreeV1: "Over 200 Brands Accepted!",

    cardCashUrl: "https://www.cardcash.com",
    tloResendCodeLinkTxt: "Resend Code",
    orderCompleteShowBonusTxt: false,
    showSoundsGoodMsg: false,
    theme: {
      background_color: "#edebe9",
      hyperlink_color: "#13B2EA",
      primary_color: "#13B2EA",
      secondary_color: "#FF9C1B",
      button_background_color: "#FF9C1B",
      button_font_color: "#FFFFFF",
      sell_instructions_bg_color: "#0D0236",
      progress_bar: "#FF9C1B",
    },
  },

  "test-checkout": {
    googleAnalytics: "",
    x_cc_app: "+BRz4gO3i",
    baseURL: "https://integration-api.cardcash.com/",
    cardCashUrl: "https://test.cardcash.com",
    reduxLogger: true,
  },

  localhost: {
    googleAnalytics: "",
    x_cc_app: "+BRz4gO3i",
    baseURL: "https://integration-api.cardcash.com/",
    cardCashUrl: "https://test.cardcash.com",
    reduxLogger: true,
  },
};

export const configModule = configBuilder("checkout", Config);
