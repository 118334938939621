import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import PropTypes from "prop-types";

import { defaultAction } from "../../actions/defaultAction";
import { validBalance } from "../../utils/validators/stepOneSell_v2";
import { merchantValuesSelector } from "../../selectors";
import { score } from "../../utils/fuzzySearch";
import { SET_ERROR } from "../../constants/actionTypes";
import styled from "@emotion/styled";
import debounce from "../../utils/debounce";

import { mediaPhone, mediaDesktop } from "../../layouts/styles/Breakpoints";

import {
  CARD_PARTIAL_MERCHANT,
  CARD_PARTIAL_BALANCE,
  DISPLAY_NO_ERROR_PARTIAL,
} from "../../constants/actionTypes";

const Container = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  display: flex;
  width: 100%;
  border: 0px;
  box-sizing: unset;
  vertical-align: unset;

  ${mediaDesktop} {
    height: 130px;
    flex-direction: row;
  }
  ${mediaPhone} {
    flex-direction: column;
  }
`;

const Value = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0;

  img {
    width: 40px;
    height: 29px;
    margin-left: 2px;
  }

  span {
    margin-left: 5px;
  }
`;

const Options = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  border-bottom: 2px solid rgba(51, 67, 61, 0.1);

  img {
    width: 40px;
    height: 29px;
    margin-left: 5px;
  }

  span {
    margin-left: 5px;
  }
`;

const Merchants = styled.div`
  ${mediaDesktop} {
    margin-right: 20px;
    flex: 1;
  }
  ${mediaPhone} {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const Balance = styled.div`
  ${mediaDesktop} {
    width: 40%;
    margin-top: 1px;
  }
  ${mediaPhone} {
    width: 100%;
  }
`;

const InputCard = ({
  offerMerchantDropDownPlaceHolder,
  offerBalanceInputPlaceHolder,
  cardPartial,
  primaryColor,
  variant,
}) => {
  const dispatch = useDispatch();
  const merchantOptions = useSelector((state) => merchantValuesSelector(state));
  const cardPartialBalance = (e) => {
    debounce(
      dispatch(defaultAction(CARD_PARTIAL_BALANCE, e.target.value)),
      600
    );
  };
  const checkBalance = () => validBalance(cardPartial, dispatch);
  const submitCardPartialMerchant = (merchant) => {
    dispatch(defaultAction(DISPLAY_NO_ERROR_PARTIAL));
    Array.isArray(merchant) && !merchant.length
      ? dispatch(defaultAction(CARD_PARTIAL_MERCHANT, ""))
      : dispatch(defaultAction(CARD_PARTIAL_MERCHANT, merchant));
  };
  const filterOption = (merchant, text) =>
    !!text ? score(merchant.data, text) : true;
  const ValueContainer = ({ children, ...props }) => (
    <components.ValueContainer {...props}>
      {props.hasValue ? (
        <Value>
          <img src={props.getValue()[0].image} />{" "}
          <span>{props.getValue()[0].label}</span>
        </Value>
      ) : (
        children
      )}
    </components.ValueContainer>
  );
  const OptionContainer = (props) => (
    <Options ref={props.innerRef} {...props.innerProps}>
      <img src={props.data.image} /> <span>{props.data.label}</span>
    </Options>
  );
  useEffect(() => {
    if (cardPartial.displayMerchantError) {
      dispatch({
        type: SET_ERROR,
        payload: "Please select a merchant",
      });
    }
    if (cardPartial.displayBalanceError) {
      dispatch({
        type: SET_ERROR,
        payload: "Please enter a card value!",
      });
    }
    if (cardPartial.displayMaxError) {
      dispatch({
        type: SET_ERROR,
        payload: `$ ${
          cardPartial.selectedMerchant &&
          cardPartial.selectedMerchant.limit &&
          cardPartial.selectedMerchant.limit.max
        }
        is the max balance`,
      });
    }
    if (cardPartial.displayMinError) {
      dispatch({
        type: SET_ERROR,
        payload: `$ ${
          cardPartial.selectedMerchant &&
          cardPartial.selectedMerchant.limit &&
          cardPartial.selectedMerchant.limit.min
        }
        is the min balance`,
      });
    }
    if (cardPartial.displayCardBalanceFormatError) {
      dispatch({
        type: SET_ERROR,
        payload: "Invalid balance",
      });
    }

    return () => {
      dispatch({
        type: SET_ERROR,
        payload: "",
      });
    };
  }, [cardPartial]);

  return (
    <>
      <Container>
        <Merchants>
          <Select
            filterOption={filterOption}
            placeholder={offerMerchantDropDownPlaceHolder}
            value={cardPartial.selectedMerchant}
            onChange={submitCardPartialMerchant}
            options={merchantOptions}
            isClearable={true}
            className={"form-control"}
            components={{ Option: OptionContainer, ValueContainer }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#FFFFFF",
                primary: "#FFFFFF",
              },
            })}
            styles={{
              indicatorSeparator: (styles) => ({ ...styles, width: "0px" }),
              control: (styles) => ({
                ...styles,
                borderStyle: "none",
                borderWidth: "0px",
                borderRadius: "0px",
                borderBottom: `1px solid ${primaryColor}`,
              }),
              menu: (styles) => ({
                ...styles,
                marginTop: "0px",
                borderRadius: "0 0 30px 30px",
                overflow: "hidden",
                borderStyle: "none",
              }),
              menuList: (styles) => ({
                ...styles,
                maxHeight: "14rem",
                overflowX: "hidden",
              }),
              placeholder: (styles) => ({
                ...styles,
                color: "#33433d",
                opacity: "1",
                fontSize: `${variant === "website" ? "1rem" : "13px"}`,
              }),
            }}
          />
        </Merchants>

        <Balance>
          <input
            onBlur={() => debounce(checkBalance, 400)}
            value={cardPartial.balance}
            onChange={cardPartialBalance}
            type="text"
            className={"form-control"}
            placeholder={offerBalanceInputPlaceHolder}
          />
        </Balance>
      </Container>
    </>
  );
};

InputCard.propTypes = {
  offerMerchantDropDownPlaceHolder: PropTypes.string.isRequired,
  offerBalanceInputPlaceHolder: PropTypes.string.isRequired,
  primaryColor: PropTypes.string.isRequired,
  cardPartial: PropTypes.object.isRequired,
  variant: PropTypes.string.isRequired,
};

export default InputCard;
