import { widgetSelector } from "./selector";
import { setPayload, open, clear } from "./action";
import { closeWidget } from "./thunk";

export { widgetSelector, closeWidget };
export const openWidget = (payload, store) => {
  return new Promise((resolve, reject) => {
    store.dispatch(setPayload(payload));
    store.dispatch(open());

    store.subscribe(() => {
      const { widget } = store.getState();

      if (!widget.isOpen && widget.completed) {
        if (Object.keys(widget.error).length) {
          reject(widget.error);
        } else {
          resolve(widget.payload);
        }

        store.dispatch(clear());
      }
    });
  });
};
